/**
 * News component.
 * @module components/News/News
 */

import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Card, Segment, Grid, Label, Header, Container } from 'semantic-ui-react';
import { defineMessages, useIntl, FormattedMessage,  } from 'react-intl';
import { searchContent, getVocabulary } from '@plone/volto/actions';
import { UniversalLink, FormattedDate, Image } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { keys, isEmpty, map, filter } from 'lodash';
import { Carousel } from '../';
import { BodyClass } from '@plone/volto/helpers';


const SLIDE_OPTIONS = {
  loop: false,
  customClass: 'instrument-news-slider',
  align: 'start',
  buttons: true,
  dots: false,
}
const vocabularyNewsitemTypes = 'product.alba.vocabularies.NewsitemTypes';

const ItemView = props => {
  const {item} = props
  const itemsNewsitemTypes = useSelector(
    (state) =>
      state.vocabularies[vocabularyNewsitemTypes] && state.vocabularies[vocabularyNewsitemTypes].items
        ? state.vocabularies[vocabularyNewsitemTypes].items
        : [],
    shallowEqual,
  );

  const getNewsItemType = function(typeN) {
    if (!itemsNewsitemTypes) {
      return typeN
    }
    for (let item of itemsNewsitemTypes) {
      if (item.value === typeN) return item.label
    }
    return typeN
  }

  return (
    <Card fluid key={item['@id']}  as="figure" className={'newsItem ' + (item?.hasPreviewImage ? 'newsItem-hasImage' : 'newsItem-noImage')} >

        <div className="ui image newsItem__img__wrapper">
          <Image 
            className="newsItem__img"
            item={item}
            loading='lazy'
            alt=""
            sizes="(max-width:575px) 100vw, 48rem"
          />
          {item?.newsitem_types ? (
            <Label.Group className="subjects newsItem__type">
              {map(item.newsitem_types, (item, index) => (
                <Label key={index}>{getNewsItemType(item)}</Label>
              ))}
            </Label.Group>
          ) : null}
        </div>

        <Card.Content as="figcaption" className="newsItem__text">                            

          <Card.Meta>
            <FormattedDate date={item.effective} className="newsItem__date "
              format={{
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              }}
            />
          </Card.Meta>

          <Card.Header>
            <UniversalLink
              className="newsItem__link newsItem__title stretched-link" 
              href={flattenToAppURL(item['@id'])}>
              {item.title}
            </UniversalLink>
          </Card.Header>

        </Card.Content>

    </Card>
  )
};


const News = props => {
  const { content } = props;
  const dispatch = useDispatch();
  const intl = useIntl();
  const navroot = useSelector((state) => state.navroot.data);
  const navRootPath = flattenToAppURL(navroot?.navroot?.['@id']) || '/';
  const news = useSelector((state) => state.search.subrequests.news?.items);
  const getNewsURL = function (content) {
    let language = content.language.token
    let path = '/' + content.id

    if (language === 'ca')
      path = '/' + language + '/public/noticies'
    if (language === 'es')
      path = '/' + language + '/publico/noticias'
    if (language === 'en')
      path = '/' + language + '/public/news'

    return flattenToAppURL(path)
  }
  const newsURL = getNewsURL(content)

  const getHighlightsURL = function (content) {
    let language = content.language.token
    let path = '/' + content.id;

    if (language === 'ca')
      path = '/' + language + '/infraestructures/linies-de-llum/' + content.id + '/science-highlights';
    if (language === 'es')
      path = '/' + language + '/infraestructuras/lineas-de-luz/' + content.id + '/science-highlights';
    if (language === 'en')
      path = '/' + language + '/infraestructures/beamlines/' + content.id + '/science-highlights';

    return flattenToAppURL(path);
  };
  const highlightsURL = getHighlightsURL(content);

  useEffect(() => {
    dispatch(
      searchContent(
        navRootPath,
        {
          portal_type: ['News Item'],
          review_state: 'published',
          sort_on: 'effective',
          sort_order: 'reverse',
          related_instruments: content.UID,
          b_size: 8,
          metadata_fields: ['effective', 'newsitem_types', 'hasPreviewImage']
        },
        'news',
      ),
    );
    dispatch(getVocabulary({ vocabNameOrURL: vocabularyNewsitemTypes, lang: content.language.token }));
  }, [dispatch, navRootPath]);


  return !isEmpty(news) ? (
    <>
      <Segment as="section" basic id="news" className="news relatedNews relatedNews--instrument">
        <div className="py-5">
          <Container>
            <Header as="h2" size="huge" className="relatedNews__title" ><FormattedMessage id="Highlights" defaultMessage="Highlights"/></Header>
            <Carousel slides={news.slice(0, 8)} ItemView={ItemView} options={SLIDE_OPTIONS} />        
          </Container>
          <p className="text-center mt-4">
            <UniversalLink href={highlightsURL} className="ui primary button mt-lg-auto mb-4 d-print-none" >
              <FormattedMessage id="View more" defaultMessage="View more"  /> <FontAwesomeIcon icon={faArrowRight} className="ms-4 me-n1" />
            </UniversalLink>
          </p>
        </div>

      </Segment>
    </>
  ) : (
    <></>
  );
};

export default News;